
.dropdown_button {
    color: white;
    padding: 13px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.place {
    /* display: none; */
    position: absolute;
    width: 87%;
    padding-top: 15px;
    z-index: 1;
}

.place li {
    color: white;
    text-decoration: none;
    display: block;
    list-style: none;
    font-family: Poppins;
    border: 1px solid #656565;
    padding: 5px;
    font-weight: 400;
    background-color: rgba(113, 113, 113, 0.9);
    z-index: 50;   
}

.place li:hover {
    background-color: #ffffff;
    color: #2D2D2D;
    font-weight: 400;
    z-index: 1; 
}
.nameofplace{
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
@media(max-width:768px){
.place {
    /* display: none; */
        position: absolute;
        width: 90%;
        padding-top: 9px;
        z-index: 1;   
        justify-content: center
}

.place li {
    color: white;
    text-decoration: none;
    display: block;
    list-style: none;
    font-family: Poppins;
    border: 1px solid #656565;
    padding: 5px;
    font-weight: 400;
    background-color: rgba(113, 113, 113, 0.9);
    
}

.place li:hover {
    background-color: #ffffff;
    color: #2D2D2D;
    font-weight: 400;
}
}
@media(max-width: 912px) and (min-width: 768px){
    .place {
        /* display: none; */
        position: absolute;
        width: 95%;
        padding-top: 9px;
        z-index: 1;   
        justify-content: center
    }
    
    .place li {
        color: white;
        text-decoration: none;
        display: block;
        list-style: none;
        font-family: Poppins;
        border: 1px solid #656565;
        padding: 5px;
        font-weight: 400;
        background-color: rgba(113, 113, 113, 0.9);
        
    }
    
    .place li:hover {
        background-color: #ffffff;
        color: #2D2D2D;
        font-weight: 400;
    }
    }
    @media(max-width:768px){
.place {
    /* display: none; */
        position: absolute;
        width: 90%;
        padding-top: 9px;
        z-index: 1;   
        justify-content: center
}

.place li {
    color: white;
    text-decoration: none;
    display: block;
    list-style: none;
    font-family: Poppins;
    border: 1px solid #656565;
    padding: 5px;
    font-weight: 400;
    background-color: rgba(113, 113, 113, 0.9);
    
}

.place li:hover {
    background-color: #ffffff;
    color: #2D2D2D;
    font-weight: 400;
}
}