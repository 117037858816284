

.App {
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Keyframe animation for the typewriter effect */
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}


/* Styling for the button */
.typewriter-button {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

/* Animation for the button text */
.typewriter-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: typewriter 5s steps(40);
}

.after
{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.before
{
  opacity: 0;
}
.after.fade
.MuiBox-root.css-tccdxk
{
  gap:12px
}
.MuiOtpInput-Box 
{
  display: flex;
  justify-content: center;
 
  gap:12px
}
.MuiOtpInput-TextField
{
  width: 50px;
  background-color: #F0F0F0;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-bottom: #C69130;
}
.MuiOtpInput-TextField input
{
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-bottom: 2px solid #C69130;
}

.rmdp-calendar
{
  z-index: 10;
  background-color: white;
}
.rmdp-calendar .rmdp-week-day
{
  color: #C69130;
}
.rmdp-calendar .rmdp-day.rmdp-today span
{
  background-color: lightgrey;
  color: black;
}
.rmdp-calendar .rmdp-day.rmdp-today.rmdp-selected span {
  background-color: #C69130;
  color: white;
}
.rmdp-calendar .rmdp-day.rmdp-selected span:not(.highlight),.rmdp-calendar .rmdp-range.start, .rmdp-calendar .rmdp-range.end
{
  background-color: #C69130;
  color: white;
}

.rmdp-calendar .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover, .rmdp-calendar .rmdp-range-hover,.rmdp-calendar .rmdp-range
{
  background-color: #AC905D;
}


.custom-select
{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

}

.active
{
  color: #C69130 ;
  border-bottom: 4px solid #C69130;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: transform 0.3s ease-in-out;
}
.active:hover,.inactive:hover
{
  transform: translateX(5px)
}
#reasonactive:active
{
  background-color: #b3aeae;
}


.inactive
{
  padding-bottom: 20px;
  padding-top: 20px;
  transition: transform 0.3s ease-in-out;
}

.custom .MuiStepLabel-root
{
  flex-direction: column-reverse;
  gap: 25px;
  justify-content: end;
  align-items: center;
}
.custom .MuiStepLabel-root .MuiSvgIcon-root.Mui-active
{
  color: #C69130;
}
.custom .MuiStepLabel-root .MuiSvgIcon-root.Mui-completed
{
  color: #C69130;
}
.custom.MuiStepper-root
{
  align-items: end;
}
.custom .MuiStepConnector-root
{
  margin-bottom: 10px;
}

.custom-slider
{
  /* background-color: black !important; */
  padding: 0 !important;
}

.custom-slider .MuiSlider-track
{
  color: #2D9CDB;
  padding: 0;
}
.custom-slider .MuiSlider-thumb.Mui-disabled
{
  display: none;
  padding: 0;
}


.swiper-custom-pagination
{
  text-align: center; 
}
.swiper-pagination-bullet-active {
 
  background-color: #C69130 !important;
}
#activeeffect:active,#showallphotos:active,#showallphotos:hover,#activeeffect:hover
{
   transform: translateY(4px);
}

.swiper-button-prev {
  background-image: url('Leftarrow.png')
}

.swiper-button-next {
  background-image: url("Rightarrow.png") !important;
}
@media (min-width: 1024px) {
  .modal {
    width: 40%;
  }
  .modalforreview
  {
    width: 80%;
    height: 80%;
    margin-top:5%
  }
  .modalforsuccess
  {
    width: 80%;
    height: 60%;
    margin-top:5%
  }
  .modalforcancel
  {
    width: 80%;
    height: 80%;
    margin-top:5%
  }
  .uploadbutton
  {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) and  (min-width:360px) {
  .modal {
    width: 80%;
  }
  .modalforreview
  {
    width: 100%;
    height: 100%;
  }
  .modalforsuccess
  {
    width: 80%;
    height: 40%;
    margin-top:5%
  }
  .modalforcancel
  {
    width: 80%;
    height: 50%;
    margin-top:5%
  }
  .uploadbutton
  {
    font-size: 12px !important;
  }
}
#showallphotos
{
  background: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(2px);
}

.image-container {
  perspective: 1000px; /* Set the perspective for 3D transforms */
  display: inline-block; /* Make the container take the size of the image */
  animation: rotateLogo 3s linear infinite
}

@keyframes rotateLogo {
  0%, 100% {
    transform: rotateY(0deg); /* Starting and ending position (no flip) */
  }
  50% {
    transform: rotateY(180deg); /* Middle position (flip halfway) */
  }
}
#propertyimage
{
  transition: filter 0.3s ease;
}
#propertyimage:hover
{
  filter: brightness(80%);
}
#mybookings
{
  transition: filter 0.3s ease;
}
#mybookings:hover
{
  filter: brightness(90%);
}
#maintitle
{
  transform: scale(0.94);
  animation: scale 4s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}
#span1{
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#span2{
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#span3{
  animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
#whatsapp-button {
  height: 10%;
  position: fixed;
  display: flex;
  justify-content: end;
  padding-right: 10px;
  align-items: center;
  bottom: 0%;
  right: 0%;
  z-index: 9999; /* Ensure the button is on top of other content */
}
@keyframes pulsate {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

#whatsapp-button img {
  animation: pulsate 2s ease infinite;
}


/* .react-datepicker.datePicker
{
  width: 100%;
  display: flex;
  gap: 20px;
  z-index: 2;
  font-weight: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 42px 20px;
}
.datePicker .react-datepicker__header {
  background-color: white;
  color: #0C0C0C;
  margin-bottom: 11px;
}
.datePicker .react-datepicker__navigation
{
  background-color: #C69130;
}
.datePicker .react-datepicker__current-month {

  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.datePicker .react-datepicker__day--selected {
  background-color: #C69130;
  color: #fff;
}
.datePicker .react-datepicker__day--selected:hover {
  background-color: #C69130;
  color: #fff;
}
.datePicker .react-datepicker__day--selected--in-range:hover {
  background-color: #C69130;
  color: #fff;
}
.datePicker .react-datepicker__day--in-selecting-range {
  background-color: #C69130;
  color: #fff;
}


.datePicker .react-datepicker__day--in-range {
  background-color: #F1E7DC;
  color:#C69130 ;
}
.datePicker .react-datepicker__day--in-range:hover {
  background-color:#C69130 ;
  color:white ;
} */

