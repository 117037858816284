.HTMLContentStyle{
  font-family: Poppins;
  /* height: 100px; */
}
.HTMLContentStyle h1 {
    font-size: 14px;
    padding-bottom: 5px;
    font-weight: 700;
    color: #2D2D2D;
  }
  .HTMLContentStyle h2 {
    font-size: 14px;
    padding-bottom: 5px;
    font-weight: 700;
    color: #2D2D2D;
  }
  
  .HTMLContentStyle ul {
    /* list-style-type: disc; */
    padding-left: 10px; 
  }
  
  .HTMLContentStyle li {
    /* padding-bottom: 10px; */
    list-style-type: disc;
    color: #656565;
  }
  